<template>
  <div class="w-full">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <button
      v-if="visibleButtonSaveTemp && step === 4"
      class="btn-success btn-large p-10 mr-1 btn-save-temporaly"
    >
      <i class="fas fa-bookmark mr-3"></i>
      <small>{{ msgButtonTemp }}</small>
    </button>
    <div v-if="step === 1" class="w-100 d-flex search">
      <button class="btn-primary p-10 mr-1">
        <i class="fas fa-filter mr-3"></i>
        <small>Filtro de búsqueda</small>
      </button>
      <button v-on:click="changeStep(2)" class="btn-primary p-10">
        <i class="far fa-plus-square mr-3"></i>
        <small>Nueva recomendación</small>
      </button>
      <input
        v-model="filterSearch"
        class="ms-auto"
        placeholder="Buscar un usuario en particular"
      />
      <button class="btn-square btn-primary flex-center">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div v-if="step === 1" class="table d-flex w-100 mt-5">
      <div class="tableHeader w-100 d-flex">
        <div
          v-for="(item, index) in dataTable.header"
          v-bind:key="index"
          :class="item.style"
        >
          <h3>{{ item.title }}</h3>
        </div>
      </div>
      <div
        v-for="(item, index) in dataRows"
        v-bind:key="JSON.stringify(item)"
        class="tableRow w-100 d-flex"
      >
        <div class="w-20">
          <small>{{ item.name }}</small>
        </div>
        <div class="w-15">
          <small>{{ formatDate(item.createdAt) }}</small>
        </div>
        <div class="w-10">
          <div :class="formatStatus(item.status)">
            <small>{{ item.status }}</small>
          </div>
        </div>
        <div class="w-10">
          <div class="flag flex-center">
            <small>{{ item.products }}</small>
            <i class="far fa-plus-square ml-1"></i>
          </div>
        </div>
        <div class="w-5">
          <div class="flag flex-center">
            <small>{{ item.reasons }}</small>
            <i class="far fa-plus-square ml-1"></i>
          </div>
        </div>
        <div class="w-40">
          <button class="btn-square-small btn-white mr-1">
            <i class="fas fa-eye"></i>
          </button>
          <button class="btn-square-small btn-white mr-1">
            <i class="fas fa-pen"></i>
          </button>
          <button class="btn-square-small btn-white mr-1">
            <i class="fas fa-download"></i>
          </button>
          <button
            v-if="item.actionPlans.length > 0"
            v-on:click="changeShowTab(index)"
            class="btn-square-small btn-gray mr-1"
          >
            <i v-if="showTab === index" class="fas fa-chevron-up"></i>
            <i v-else class="fas fa-chevron-down"></i>
          </button>
          <button class="btn-small btn-gray">
            <small>Cerrar</small>
          </button>
        </div>
        <div v-if="showTab === index" class="w-100 d-flex flex-wrap">
          <div
            v-for="item2 in item.actionPlans"
            v-bind:key="JSON.stringify(item2)"
            class="w-100 d-flex p-10"
          >
            <div class="w-20 p-10 border-bottom-gray">
              <small>{{ item2.name }}</small>
            </div>
            <div class="w-20 p-10 border-bottom-gray">
              <small>{{ formatDate(item2.createdAt) }}</small>
            </div>
            <div class="w-35 p-10 border-bottom-gray">
              <div class="progressbar">
                <div :class="'progressbar-content w-' + item2.progress">
                  <small>{{ item2.progress }}%</small>
                </div>
              </div>
            </div>
            <div class="w-5 p-10 border-bottom-gray">
              <button class="btn-square-small btn-white">
                <i class="fas fa-eye"></i>
              </button>
            </div>
            <div class="w-5 p-10 border-bottom-gray">
              <button class="btn-square-small btn-white">
                <i class="fas fa-download"></i>
              </button>
            </div>
            <div class="w-15 p-10 border-bottom-gray flex-center">
              <button class="btn-warning flag">
                <small>Seguimiento</small>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="tableControls w-100 d-flex p-10">
        <button
          class="ml-auto mr-4 btn-primary-light btn-square-small"
          v-on:click="pagination(1)"
        >
          <i class="fas fa-angle-double-left"></i>
        </button>
        <button
          class="mr-4 btn-primary-light btn-square-small"
          v-on:click="pagination(page - 1)"
        >
          <i class="fas fa-angle-left"></i>
        </button>
        <h5 class="mr-4 mt-auto mb-auto">{{ page }} de {{ totalPages }}</h5>
        <button
          class="mr-4 btn-primary-light btn-square-small"
          v-on:click="pagination(page + 1)"
        >
          <i class="fas fa-angle-right btn-primary-light btn-square-smal"></i>
        </button>
        <button
          class="mr-4 btn-primary-light btn-square-small"
          v-on:click="pagination(totalPages)"
        >
          <i class="fas fa-angle-double-right"></i>
        </button>
      </div>
    </div>
    <DynamicForm
      v-if="step === 2"
      @methodCancel="changeStep"
      :urlform="'http://localhost:8080/forms/newRecommendation.json'"
    />
  </div>
</template>

<script>
// #004884
import DynamicForm from "@/components/DynamicForm/";

export default {
  components: { DynamicForm },
  data() {
    return {
      filterSearch: "",
      step: 1,
      showTab: -1,
      visibleButtonSaveTemp: true,
      dataTable: {
        header: [
          { title: "Nombre", style: "w-20" },
          { title: "Fecha creación", style: "w-15" },
          { title: "Estado", style: "w-10" },
          { title: "Productos", style: "w-10" },
          { title: "Motivos", style: "w-5" },
          { title: "Acciones", style: "w-40" },
        ],
        content: [],
      },
      loading: false,
      page: 1,
      limit: 10,
      totalPages: 1,
      dataRows: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      let { error, data } = await this.$api.getRecommendations(
        `?page=${this.page}&page_size=${this.limit}`
      );
      this.loading = false;
      if (error) {
        console.log("error in getData | err:", error);
        return;
      }
      
      this.totalPages = data.pages;
      this.dataRows = data.results.map((row) => {
        return {
          id: row.id,
          name: row.company,
          createdAt: row.created_at,
          status: row.status_name,
          actionPlans: row.action_plans.map((ap) => {
            return {
              id: ap.id,
              name: ap.description,
              createdAt: ap.created_at,
              files: ap.files,
              progress: "60",
            };
          }),
          products: 0,
          reasons: 0,
        };
      });
      console.log("hello data", data);
    },
    pagination(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.page = newPage;
      }
      this.getData();
    },
    currentDate() {
      // Obtener la fecha actual
      const today = new Date();

      // Obtener los componentes de la fecha
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por lo que se suma 1
      const day = String(today.getDate()).padStart(2, "0");

      // Crear la cadena en el formato deseado
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = event.target.files;

      if (files.length > 5) {
        alert("Selecciona máximo 5 archivos.");
        return;
      }

      this.files = files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 1024 * 1024) {
          alert(
            "El archivo " +
              file.name +
              " excede el tamaño máximo permitido (1 MB)."
          );
          return;
        }

        console.log("Archivo seleccionado:", file);
      }
    },
    changeShowTab(index) {
      if (this.showTab === index) {
        this.showTab = -1;
      } else {
        this.showTab = index;
      }
    },
    saveRecommendation() {
      this.step = 1;
    },
    changeReports(addReport = true, index) {
      if (addReport) {
        this.newRecommendation.reports.push({});
      } else {
        let arrayPivot = [...this.newRecommendation.reports];
        arrayPivot.splice(index, 1);
        this.newRecommendation.reports = arrayPivot;
      }
    },
    changeStep(step = 1) {
      this.step = step;
    },
    saveTemporaly() {
      this.msgButtonTemp = "Guardado!";
      setTimeout(() => {
        this.visibleButtonSaveTemp = false;
      }, 2000);
      localStorage.setItem(
        "newRecommendationFormSave",
        JSON.stringify(this.dataForm)
      );
    },
    formatStatus(status, defaultClass) {
      const obj = {
        Borrador: "btn-warning-light",
        Formulado: "btn-success-light",
        Finalizado: "btn-success",
        Cerrado: "btn-danger-light",
        Seguimiento: "btn-primary-light",
      };
      return obj[status] + " " + defaultClass;
    },
    formatDate(inputDate) {
      // Convierte la cadena de entrada en un objeto Date
      const date = new Date(inputDate);

      // Obtiene los componentes de la fecha
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Añade cero al principio si es necesario
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      // Calcula la hora en formato 12 horas
      const formattedHours = hours % 12 || 12;

      // Formatea la fecha en el formato deseado en inglés
      const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;

      return formattedDate;
    },
  },
};
</script>

<style>
.accordion-100 > div {
  width: 100%;
}
.selection-padding-10 > div > div > div,
.selection-padding-10 > div > input {
  padding: 10px !important;
  min-height: 52px;
}
button {
  border-radius: 5px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.p-10 {
  padding: 10px;
}
.d-flex {
  display: flex;
}
.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.ms-auto {
  margin-left: auto;
}
.text-primary {
  color: #004884;
}
.btn-large {
  min-width: 300px;
}
.btn-small {
  min-width: 90px;
  padding: 3px;
}
.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}
.btn-square {
  width: 40px;
  height: 40px;
}
.btn-square-small {
  width: 26px;
  height: 26px;
}
.btn-gray {
  background: #e7e7e7;
  color: black;
}
.btn-primary {
  background: #004884;
  color: white;
}
.btn-warning {
  background: #f8b446;
  color: white;
}
.btn-danger {
  background: #ea4a76;
  color: white;
}
.btn-success {
  background: #309d7e;
  color: white;
}
.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}
.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}
.btn-primary-light {
  background: #edf3ff;
  color: #004884;
}
.btn-success-light {
  background: #e5fbf5;
  color: #309d7e;
}
.no-border {
  border: 0px !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}
.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.search > input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}
.search > button {
  border-radius: 10px;
}
.table {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}
.tableHeader {
  font-weight: bold;
}
.table > div {
  flex-wrap: wrap;
}
.table > div > div {
  border-bottom: 1px solid black;
  padding: 10px 1px;
}
.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}
.progressbar > div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #309d7e;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}
.w-40 {
  width: 40%;
}
.w-35 {
  width: 35%;
}
.w-30 {
  width: 30%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}
.w-10 {
  width: 10%;
}
.w-5 {
  width: 5%;
}
.d-none {
  display: none;
}
.containerFileOptional {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  padding: 20px;
  border-radius: 15px;
}
.containerFileOptional div {
  border-radius: 15px;
  background: rgba(51, 102, 204, var(--tw-bg-opacity));
  margin-bottom: 10px;
  color: white;
  padding: 0.5em 4em;
}
.btn-save-temporaly {
  position: fixed;
  right: 40px;
  top: 200px;
  z-index: 10000;
}
.cursor {
  cursor: pointer;
}
</style>